import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

const ScheduleMyPickup = () => (
  <Layout>
    <Seo title="Schedule My Pickup" />
    <div className="flex flex-wrap">
      <div className="w-full m-auto text-grey-darker">
        <div style={{ height: "800px" }}>
          <iframe
            src="https://calendly.com/denverwwc/pick-up-my-cover"
            width="100%"
            height="100%"
            frameborder="0"
            title="Schedule My Pickup"
          ></iframe>
        </div>
      </div>
    </div>
  </Layout>
);

export default ScheduleMyPickup;
